import React from "react";
import styles from "./HolidaysContainer.module.scss";
import {Holidays} from "..";

export const HolidaysContainer = (): JSX.Element => {
  return (
    <div className={styles.holidayContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.titleSection}>
          <div className={styles.innerContainerTitle}>Holidays Settings</div>
        </div>
        <Holidays />
      </div>
    </div>
  );
};
