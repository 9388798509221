import React, {FC, useState, useEffect} from "react";
import styles from "./CaseUnitReservation.module.scss";
import {CurrencyComponent} from "../currency/CurrencyComponent";
import {RelocationCaseAttributesType} from "@common/typing";
import {FrontendConfiguration} from "@common/configuration";
import {AlertComponent} from "../alert/AlertComponent";
import {calculateDaysDiff, isCaseResolved} from "../../utils";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {patchRelocationCase} from "../../store/caseProfileSlice";

interface CaseUnitReservationProps {
  unitId: number;
  unitCode: string;
  unitName: string;
  unitCity: string;
  unitState: string;
  numberOfGuest: number;
  numberOfDogs: number;
  totalRent: number;
  nightlyRate: number;
  fees: number;
  taxes: number;
  insurance: string;
  insuranceValue: number;
  totalCost: number;
  nightlyCoverage: string;
  totalCoverage: string;
}

export const CaseUnitReservation: FC<CaseUnitReservationProps> = ({
  unitId,
  unitCode,
  unitName,
  unitCity,
  unitState,
  numberOfGuest,
  numberOfDogs,
  totalRent,
  nightlyRate,
  fees,
  taxes,
  insurance,
  insuranceValue,
  totalCost,
  nightlyCoverage,
  totalCoverage,
}) => {
  // redux state
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.caseProfile.reservation) as any;
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;

  // current component state
  const [nightlyCoverageValue, setNightlyCoverageValue] = useState("0.00");
  const [totalCoverageValue, setTotalCoverageValue] = useState("0.00");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [validNightlyCoverage, setValidNightlyCoverage] = useState(false);
  const [validTotalCoverage, setValidTotalCoverage] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const isReadOnly = isCaseResolved(relocationCase);

  useEffect(() => {
    nightlyCoverage !== null ? setNightlyCoverageValue(nightlyCoverage) : setNightlyCoverageValue("0.00");
  }, [nightlyCoverage]);

  useEffect(() => {
    totalCoverage !== null ? setTotalCoverageValue(totalCoverage) : setTotalCoverageValue("0.00");
  }, [totalCoverage]);

  useEffect(() => {
    setTotalDays(calculateDaysDiff(reservation?.attributes?.first_night?.split("T")[0], reservation?.attributes?.last_night?.split("T")[0]));
  }, []);

  const onChangeNightlyCoverage = (value: string) => {
    const nightlyCoverage = parseFloat(value.replace(/,/g, ""));
    if (value !== "" && !isNaN(nightlyCoverage) && nightlyCoverage >= 0) {
      setValidNightlyCoverage(true);
      setShowAlert(false);
      setAlertMessage("Success! Nightly and Total Coverage updated.");
      setAlertType("success");
      setNightlyCoverageValue(value);
    } else {
      setValidNightlyCoverage(false);
      setAlertMessage("Error! Nightly Coverage invalid value.");
      setAlertType("error");
      setShowAlert(true);
    }
  };

  const onChangeTotalCoverage = (value: string) => {
    const totalCoverage = parseFloat(value.replace(/,/g, ""));
    if (value !== "" && !isNaN(totalCoverage) && totalCoverage >= 0) {
      setValidTotalCoverage(true);
      setShowAlert(false);
      setAlertMessage("Success! Nightly and Total Coverage updated.");
      setAlertType("success");
      setTotalCoverageValue(value);
    } else {
      setValidTotalCoverage(false);
      setAlertMessage("Error! Total Coverage invalid value.");
      setAlertType("error");
      setShowAlert(true);
    }
  };

  const updateCaseNightlyCoverage = async () => {
    if (relocationCase.id !== undefined && validNightlyCoverage) {
      const relocationCaseData: RelocationCaseAttributesType = {
        budget_nightly_coverage: parseFloat(nightlyCoverageValue.replace(/,/g, "")),
        budget_total_coverage:
          typeof totalCoverageValue !== "number" ? parseFloat(totalCoverageValue.replace(/,/g, "")) : parseFloat(totalCoverageValue),
      } as RelocationCaseAttributesType;
      await dispatch(patchRelocationCase({...relocationCaseData, id: relocationCase.id}));
      setShowAlert(true);
    }
  };

  const updateCaseTotalCoverage = async () => {
    if (relocationCase.id !== undefined && validTotalCoverage) {
      const relocationCaseData: RelocationCaseAttributesType = {
        budget_nightly_coverage:
          typeof nightlyCoverageValue !== "number" ? parseFloat(nightlyCoverageValue.replace(/,/g, "")) : parseFloat(nightlyCoverageValue),
        budget_total_coverage: parseFloat(totalCoverageValue.replace(/,/g, "")),
      } as RelocationCaseAttributesType;
      await dispatch(patchRelocationCase({...relocationCaseData, id: relocationCase.id}));
      setShowAlert(true);
    }
  };

  return (
    <div className={styles.caseUnitReservation}>
      <div className={styles.caseUnitReservationTitle}>Unit and Reservation</div>
      <div className={styles.caseUnitReservationInfo}>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Unit code</div>
          <div className={styles.caseUnitReservationContentText}>
            <a
              href={FrontendConfiguration.getAdminUrl() + `/admin/dashboard/units/edit?UnitID=${unitId}`}
              className={styles.noTextDecoration}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.caseUnitReservationContentLink}>{unitCode}</span>
            </a>
          </div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Unit name</div>
          <div className={styles.caseUnitReservationContentText}>
            <a
              href={FrontendConfiguration.getVacasaUrl() + `/unit/${unitId}`}
              className={styles.noTextDecoration}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.caseUnitReservationContentLink}>{unitName}</span>
            </a>
          </div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Unit city/state</div>
          <div className={styles.caseUnitReservationContentText}>
            {unitCity}
            <span> / </span>
            {unitState}
          </div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Number of Guests</div>
          <div className={styles.caseUnitReservationContentText}>{numberOfGuest}</div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>TP/Red Sky</div>
          <div className={styles.caseUnitReservationContentText}>{insurance}</div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Dogs</div>
          <div className={styles.caseUnitReservationContentText}>{numberOfDogs}</div>
        </div>
      </div>
      <hr />
      <div className={styles.caseUnitReservationInfo} style={{paddingBottom: 10}}>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Finances</div>
        </div>
      </div>
      <div className={styles.caseUnitReservationFinanceInfo}>
        <div>
          <div className={styles.caseUnitReservationContentText}>
            <div>
              <span>Total rent</span>
            </div>
            <div>
              <span>Nightly rate</span>
            </div>
            <div>
              <span>Fees</span>
            </div>
            <div>
              <span>Taxes</span>
            </div>
            <div>
              <span>Trip Protection:</span>
            </div>
            <div className={styles.caseUnitReservationBold}>
              <span>Total Cost</span>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentText}>
            <div>
              <span>${totalRent}</span>
            </div>
            <div>
              <span>${nightlyRate}</span>
            </div>
            <div>
              <span>${fees}</span>
            </div>
            <div>
              <span>${taxes}</span>
            </div>
            <div>
              <span>${insuranceValue}</span>
            </div>
            <div>
              <span>${totalCost}</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.caseUnitReservationSubTitle}>Budget for Coverage</div>
      {showAlert && <AlertComponent text={<>{alertMessage}</>} className={alertType} iconSize="25" setShowAlert={setShowAlert} />}
      <div className={styles.caseUnitReservationInfo} style={{paddingBottom: 0}}>
        <div>
          <div className={styles.caseUnitReservationContentTitle}># of Nights on Res</div>
          <div className={styles.caseUnitReservationContentText}>{totalDays}</div>
        </div>
        <div></div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Nightly Coverage</div>
          <div className={styles.caseUnitReservationContentInput}>
            <CurrencyComponent value={nightlyCoverageValue} onChange={onChangeNightlyCoverage} readOnly={isReadOnly} />
            {!isReadOnly && (
              <span className={styles.caseUnitReservationContentSaveBtn} onClick={updateCaseNightlyCoverage}>
                Save
              </span>
            )}
          </div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTitle}>Total Coverage</div>
          <div className={styles.caseUnitReservationContentInput}>
            <CurrencyComponent value={totalCoverageValue} onChange={onChangeTotalCoverage} readOnly={isReadOnly} />
            {!isReadOnly && (
              <span className={styles.caseUnitReservationContentSaveBtn} onClick={updateCaseTotalCoverage}>
                Save
              </span>
            )}
          </div>
        </div>
        <div>
          <div className={styles.caseUnitReservationContentTextLink}>
            <a
              href="https://docs.google.com/spreadsheets/d/14bk9S8TBDJjbeGc8TsPOpS9xDUYl1jiUivcxvdW500o/edit?usp=sharing"
              className={styles.noTextDecoration}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.caseUnitReservationContentLink}>Use Coverage Cost Calculator</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
