import {BsChevronDown, BsGearWide, BsCalendar, BsReverseLayoutTextWindowReverse} from "react-icons/bs";
import {Dropdown, Menu, Button, Space} from "antd";
import type {MenuProps} from "antd";
import React, {useState, useEffect} from "react";
import {ADMIN_USERS} from "../../constants";
import {getUserInfo} from "../../utils";
import {ROUTES} from "../../constants";

export const SettingsMenu: React.FunctionComponent<any> = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    handleVisibleChange();
  }, []);

  const handleVisibleChange = () => {
    const userInfo = getUserInfo();
    if (ADMIN_USERS.includes(userInfo.email)) {
      setVisible(true);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    handleRedirect(e.key);
  };

  const handleRedirect = (redirectTo: string) => {
    window.open(`${window.location.origin}/${redirectTo}`, "_blank");
  };

  const navigateToSubmissionForm = () => {
    handleRedirect(ROUTES.SUBMISSION_FORM);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: ROUTES.HOLIDAYS,
          label: "Holidays",
          icon: <BsCalendar />,
        },
      ]}
    />
  );

  return (
    <>
      <Space>
        <Button
          onClick={() => {
            navigateToSubmissionForm();
          }}
        >
          <Space>
            <BsReverseLayoutTextWindowReverse size="20px" />
            <span>Submission Form</span>
          </Space>
        </Button>
        {visible && (
          <Dropdown overlay={menu}>
            <Button>
              <Space>
                <BsGearWide size="20px" />
                Settings
                <BsChevronDown size="10px" />
              </Space>
            </Button>
          </Dropdown>
        )}
      </Space>
    </>
  );
};
