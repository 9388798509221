import React, {FC} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import styles from "../wrap-up-component/WrapUp.module.scss";
import {setFMPreventable, setCleanAfterStay, setCleanOwnerBilled} from "../../store/wrapUpSlice";

interface WrapUpFinalDetailsProps {}

export const WrapUpFinalDetails: FC<WrapUpFinalDetailsProps> = () => {
  const dispatch = useAppDispatch();

  const fmPreventable = useSelector((state: RootState) => state.wrapUp.details.fmPreventable) as any;
  const cleanAfterStay = useSelector((state: RootState) => state.wrapUp.details.cleanAfterStay) as any;
  const cleanOwnerBilled = useSelector((state: RootState) => state.wrapUp.details.cleanOwnerBilled) as any;

  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;

  return (
    <div className={styles.body}>
      <div className={styles.title}>Final Details</div>
      <div className={styles.containerItem}>
        <div className={styles.divSeparator}>
          <span className={styles.containerItemTitle}>Was FM Preventable?</span>
        </div>
        {!isCaseOpen ? (
          <div className={styles.containerItemDetail}>{fmPreventable ? "Yes" : "No"}</div>
        ) : (
          <div>
            <div className={styles.containerItemDetail}>
              <label className={styles.contentRadioButton}>
                <input
                  type="radio"
                  name="rbtn_finalDetailsPreventable"
                  id="rbtn_finalDetailsPreventableYes"
                  className={styles.radioButton}
                  onChange={() => dispatch(setFMPreventable(true))}
                />
                Yes
              </label>
            </div>
            <div className={styles.containerItemDetail}>
              <label className={styles.contentRadioButton}>
                <input
                  type="radio"
                  name="rbtn_finalDetailsPreventable"
                  id="rbtn_finalDetailsPreventableNo"
                  className={styles.radioButton}
                  onChange={() => dispatch(setFMPreventable(false))}
                />
                No
              </label>
            </div>
          </div>
        )}
      </div>
      <hr className={styles.contentSeparatorHr} />
      <div className={styles.containerItem}>
        <div className={styles.divSeparator}>
          <span className={styles.finalDetailsSubtitle}>Cleaning of unit</span>
        </div>
        <div className={styles.containerItemDetail}>
          The following details have been pre-selected based on the Google submission form. Adjust as needed.
        </div>
      </div>
      <div className={styles.containerItem}>
        <div className={styles.divSeparator}>
          <span className={styles.containerItemTitle}>Clean after stay?</span>
        </div>
        {!isCaseOpen ? (
          <div className={styles.containerItemDetail}>{cleanAfterStay ? "Yes" : "No"}</div>
        ) : (
          <div>
            <div className={styles.containerItemDetail}>
              <label className={styles.contentRadioButton}>
                <input
                  type="radio"
                  name="rbtn_finalDetailsClean"
                  id="rbtn_finalDetailsCleanYes"
                  className={styles.radioButton}
                  onChange={() => dispatch(setCleanAfterStay(true))}
                  checked={cleanAfterStay}
                />
                Yes
              </label>
            </div>
            <div className={styles.containerItemDetail}>
              <label className={styles.contentRadioButton}>
                <input
                  type="radio"
                  name="rbtn_finalDetailsClean"
                  id="rbtn_finalDetailsCleanNo"
                  className={styles.radioButton}
                  onChange={() => dispatch(setCleanAfterStay(false))}
                  checked={!cleanAfterStay}
                />
                No
              </label>
            </div>
          </div>
        )}
      </div>
      <div className={styles.containerItem}>
        <div className={styles.divSeparator}>
          <span className={styles.containerItemTitle}>Who’s being billed for the clean?</span>
        </div>
        {!isCaseOpen ? (
          <div className={styles.containerItemDetail}>{cleanOwnerBilled ? "Owner" : "Vacasa"}</div>
        ) : (
          <div>
            <div className={styles.containerItemDetail}>
              <label className={styles.contentRadioButton}>
                <input
                  type="radio"
                  name="rbtn_finalDetailsBilled"
                  id="rbtn_finalDetailsBilledYes"
                  className={styles.radioButton}
                  onChange={() => dispatch(setCleanOwnerBilled(false))}
                  checked={!cleanOwnerBilled}
                />
                Vacasa
              </label>
            </div>
            <div className={styles.containerItemDetail}>
              <label className={styles.contentRadioButton}>
                <input
                  type="radio"
                  name="rbtn_finalDetailsBilled"
                  id="rbtn_finalDetailsBilledNo"
                  className={styles.radioButton}
                  onChange={() => dispatch(setCleanOwnerBilled(true))}
                  checked={cleanOwnerBilled}
                />
                Owner
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
