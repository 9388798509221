import React, {useEffect, useState} from "react";
import styles from "./FollowUp.module.scss";
import {BsCheck, BsX} from "react-icons/bs";
import {RelocationService} from "@common/services";
import {CircularProgress} from "@material-ui/core";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import cx from "classnames";
import dayjs from "dayjs";
import {RootState} from "../../store/store";
import Editor from "rich-markdown-editor";
import {useSelector} from "react-redux";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface FollowUpProps {
  followUp: {
    completed: boolean;
    adjustNextFollowUp: boolean;
  };
  state: stateFollowUpProps;
  setState: Function;
  handleAddCaseHistory?: () => void;
}
interface stateFollowUpProps {
  note: string;
  selection: selectionFollowUpProps;
}
interface selectionFollowUpProps {
  email: boolean;
  text: boolean;
  phone: boolean;
}

export const FollowUp: React.FunctionComponent<FollowUpProps> = ({followUp, state, setState, handleAddCaseHistory}) => {
  //Redux State
  const {isCreatingCaseHistory, relocationCaseHistory} = useSelector((state: RootState) => state.caseHistory);
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;

  //Component State
  const [followUpNotes, setFollowUpNotes] = useState(false);
  const [noteEmpty, setNoteEmpty] = useState(true);

  /**
   * triggered when follow up checkbox are clicked
   * (email - text -phone)
   * @param HTMLInputElement evt
   */
  const handleChecked = (evt: React.ChangeEvent<HTMLInputElement>) => {
    switch (evt.target.id) {
      case "chk_followup_email":
        state.selection.email = evt.target.checked;
        break;
      case "chk_followup_text":
        state.selection.text = evt.target.checked;
        break;
      case "chk_followup_phone":
        state.selection.phone = evt.target.checked;
        break;
      default:
        break;
    }
    setState({...state});
  };

  /**
   * When the followup note is written
   * @param string evt
   */
  const handleChangeNote = (evt: string) => {
    setState({...state, note: evt.trim()});
  };

  /**
   * Validates that if the note is empty, the 'Record...' button will not be displayed
   */
  useEffect(() => {
    if (state.note && state.note !== "\\") setNoteEmpty(false);
    else setNoteEmpty(true);
  }, [state.note]);

  /**
   * Validates that there is an 'adjustNextFollowUp' or if any checkbos was clicked. The input of followUp notes is displayed.
   * otherwise, it is cleaned and the button is hidden
   */
  useEffect(() => {
    if (followUp.adjustNextFollowUp) setFollowUpNotes(true);
    else if (state.selection.email || state.selection.text || state.selection.phone) setFollowUpNotes(true);
    else {
      setFollowUpNotes(false);
      setState({...state, note: ""});
    }
  }, [followUp.adjustNextFollowUp, state.selection.email, state.selection.text, state.selection.phone]);

  return (
    <div className={styles.component}>
      {!followUp.completed && (
        <>
          {/* Follow Up Options [Email, Text, Phone] */}
          <>
            <div className={styles.divSection}>
              <input
                type="checkbox"
                name="chkFollowUp"
                id="chk_followup_email"
                data-testid="chk_followup_email"
                className={styles.checkBox}
                onChange={(e) => handleChecked(e)}
              />
              <label>Email</label>
            </div>
            <div className={styles.divSection}>
              <input
                type="checkbox"
                name="chkFollowUp"
                id="chk_followup_text"
                data-testid="chk_followup_text"
                className={styles.checkBox}
                onChange={handleChecked}
              />
              <label>Text</label>
            </div>
            <div className={styles.divSection}>
              <input
                type="checkbox"
                name="chkFollowUp"
                id="chk_followup_phone"
                data-testid="chk_followup_phone"
                className={styles.checkBox}
                onChange={handleChecked}
              />
              <label>Phone</label>
            </div>
          </>

          {/* Required Notes */}
          {followUpNotes && (
            <div className={styles.dvNotes}>
              <div className={styles.dvNotesTitle}>Notes (required)</div>
              <div>
                <Editor
                  className={cx(styles.dvNotesTextArea, noteEmpty ? styles.dvNotesTextAreaInvalid : styles.dvNotesTextAreaValid)}
                  onChange={(value: any) => handleChangeNote(value())}
                />
              </div>
              {!followUp.adjustNextFollowUp && (
                <div className={styles.btnBox}>
                  <button
                    className={cx(styles.dvNotesBtn, noteEmpty || isCreatingCaseHistory ? styles.dvNotesBtnDisabled : styles.dvNotesBtnAllowed)}
                    onClick={handleAddCaseHistory}
                    disabled={isCreatingCaseHistory || noteEmpty}
                  >
                    {relocationCase.attributes.relocation_case_task_id === RelocationService.STATUS_PENDING
                      ? "Record Initiation "
                      : "Record follow up "}
                  </button>
                  {isCreatingCaseHistory ? (
                    <div className={styles.searchingProgress}>
                      <CircularProgress color="inherit" size={25} />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </>
      )}

      {/* Initiation or Follow Up Completed */}
      {followUp.completed && (
        <>
          <div className={styles.divSectionLink}>
            <label>
              {state.selection.email ? (
                <BsCheck style={{verticalAlign: "middle", marginRight: "4px"}} className={styles.iconSuccess} />
              ) : (
                <BsX style={{verticalAlign: "middle", marginRight: "4px"}} className={styles.iconDanger} />
              )}
              Email
            </label>
          </div>
          <div className={styles.divSectionLink}>
            <label>
              {state.selection.text ? (
                <BsCheck style={{verticalAlign: "middle", marginRight: "4px"}} className={styles.iconSuccess} />
              ) : (
                <BsX style={{verticalAlign: "middle", marginRight: "4px"}} className={styles.iconDanger} />
              )}
              Text
            </label>
          </div>
          <div className={styles.divSectionLink}>
            <label>
              {state.selection.phone ? (
                <BsCheck style={{verticalAlign: "middle", marginRight: "4px"}} className={styles.iconSuccess} />
              ) : (
                <BsX style={{verticalAlign: "middle", marginRight: "4px"}} className={styles.iconDanger} />
              )}
              Phone
            </label>
          </div>
        </>
      )}
    </div>
  );
};
