import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {RelocationService, ReservationImplicitService, UnitImplicitService} from "@common/services";
import {defaultFilters, DEFAULT_RETRY_TIME, FormSubmissionSteps, requestType} from "../constants";
import {FormDataType, FormSubmissionSliceState, FormSubmissionStepPayload} from "../typing/FormSubmission";
import {requestMapper} from "../utils/Relocation-Request/relocation-request";
import {RelocationRequestReservationType, RelocationRequestUnitType} from "@common/typing";
import dayjs from "dayjs";
import {isReservationInThePast, validateExtendedEndDate, isDateValid} from "../utils/Dates/dates";

export const createsRelocationRequest = createAsyncThunk("formSubmission/submitForm", async (data: FormDataType, {rejectWithValue}) => {
    let payload = requestMapper(data, data.type);
    const relocationService = RelocationService.getInstance();
    try {
        const response =
            parseInt(data.type) === requestType.unit
                ? await relocationService.createRelocationRequestUnit(payload as RelocationRequestUnitType)
                : await relocationService.createRelocationRequestReservation(payload as RelocationRequestReservationType);
        return response;
    } catch (error: any) {
        if (!error) {
            //to check if its an availability problem, this wait for the retry in service and search for case created
            await new Promise((resolve) => setTimeout(resolve, DEFAULT_RETRY_TIME));
            const unitCode = parseInt(data.type) === requestType.unit ? data.legacyUnitCode : data.legacyReservationId;
            const response = await RelocationService.getInstance().getRelocationCases({
                ...defaultFilters,
                unitCode: unitCode,
            });
            if (response.data.length) return response;
            return rejectWithValue("This request is taking too long, please wait a moment and try again");
        }
        return rejectWithValue(error.data.detail);
    }
});

export const validatesReservation = createAsyncThunk(
    "formSubmission/validateReservation",
    async ({reservationId, extendedEndDate = ""}: {reservationId: number; extendedEndDate?: string}, {rejectWithValue}) => {
        const reservationService = ReservationImplicitService.getInstance();
        const reservation = await reservationService.getReservation(reservationId, false);
        // validate that reservation dont exists
        if (reservation?.data.length === 0) {
            return rejectWithValue(`Looks like the reservation ${reservationId} doesn't exist. Please validate the Reservation ID and try again.`);
        }

        const isReservationCancelled = reservation?.data[0]?.attributes.is_cancelled;

        if (isReservationCancelled) {
            return rejectWithValue("This reservation has already been canceled.");
        }

        const checkoutDate = reservation?.data[0]?.attributes.last_night ?? null; // Use optional chaining and nullish coalescing operator

        if (isReservationInThePast(checkoutDate)) {
            return rejectWithValue(`Looks like the checkout date of this reservation is in the past. Please validate this date: ${checkoutDate}.`);
        }

        if (extendedEndDate !== "") {
            if (isDateValid(extendedEndDate)) {
                return rejectWithValue(`Looks like the extended end date field has an incorrect date format: ${extendedEndDate}.`);
            }

            if (validateExtendedEndDate(extendedEndDate, checkoutDate)) {
                return rejectWithValue(
                    `Looks like the extended end date of this reservation is before the date of the check out. Must be after the date: ${checkoutDate}.`
                );
            }
        }

        return true;
    }
);

export const validatesUnitCode = createAsyncThunk("formSubmission/validateUnitCode", async (unitCode: string, {rejectWithValue}) => {
    const unitService = UnitImplicitService.getInstance();
    const unit = await unitService.getUnitByUnitCode(unitCode);
    if (unit.data?.length === 0) {
        return rejectWithValue("Looks like this Unit Code doesn't exist. Please validate the Unit Code and try again.");
    }
    return true;
});

export const fetchRequestReasons = createAsyncThunk("formSubmission/fetchRequestReasons", async () => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.getRelocationRequestReasons();
    return response?.data;
});

const initialState: FormSubmissionSliceState = {
    //Modals, alerts, errors
    isCreatingRequest: false,
    createFormFailed: false,
    createFormErrorMessage: "Something went wrong. Please try again.",
    successSubmissionModalVisible: false,
    isValidatingReservation: false,
    validateReservationFailed: false,
    validateReservationErrorMessage: "Something went wrong. Please try again.",
    isValidatingUnit: false,
    validateUnitFailed: false,
    validateUnitErrorMessage: "Something went wrong. Please try again.",

    //Steps and Form Data
    currentStep: FormSubmissionSteps.TYPE_OF_FM,
    previousStep: FormSubmissionSteps.TYPE_OF_FM,
    stepsPath: [FormSubmissionSteps.TYPE_OF_FM],
    formData: {
        isMSFM: null,
        legacyReservationId: "",
        relocationRequestReasonId: "",
        unitCandidates: "",
        extraNotes: "",
        endDate: dayjs().format("YYYY-MM-DD").toString(),
        cleanAfterStay: "",
        type: "",
        legacyUnitCode: "",
        startDate: dayjs().format("YYYY-MM-DD").toString(),
        moveGuest: "",
        approvedBy: "",
        billedPercentage: "",
        billed: "",
        createdBy: "",
        extendedEndDate: "",
    },
    relocationRequestReasons: [],
};

const formSubmissionSlice = createSlice({
    name: "formSubmission",
    initialState,
    reducers: {
        updateStep: (state, {payload}: PayloadAction<FormSubmissionStepPayload>) => {
            state.currentStep = payload.currentStep;
            state.previousStep = payload.previousStep;
        },
        updateFormData: (state, payload) => {
            state.formData = {...state.formData, ...payload.payload};
        },
        refreshStepPath: (state, {payload}) => {
            switch (payload.buttonAction) {
                case "next":
                    state.stepsPath = !state.stepsPath.includes(payload.currentStep)
                        ? [...state.stepsPath, payload.currentStep]
                        : [...state.stepsPath];
                    break;
                case "back":
                    state.stepsPath = state.stepsPath.slice(undefined, -1);
                    break;
                case "edit":
                    state.stepsPath = state.stepsPath.slice(0, state.stepsPath.findIndex((element) => element === payload.currentStep) + 1);
                    break;
                default:
                    break;
            }
        },
        hideConfirmationModal: (state) => {
            state.successSubmissionModalVisible = false;
        },
        resetBilledInfo: (state) => {
            state.formData.approvedBy = "";
            state.formData.billedPercentage = "";
        },
        resetSteps: (state) => {
            state.currentStep = initialState.currentStep;
            state.previousStep = initialState.previousStep;
            state.stepsPath = initialState.stepsPath;
            state.formData = initialState.formData;
        },
        closeCreateFormFailedAlert: (state) => {
            state.createFormFailed = false;
        },
        closeValidateReservationFailedAlert: (state) => {
            state.validateReservationFailed = false;
        },
        closeValidateUnitCodeFailedAlert: (state) => {
            state.createFormFailed = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRequestReasons.fulfilled, (state, {payload}) => {
                state.relocationRequestReasons = payload;
            })
            .addCase(createsRelocationRequest.pending, (state) => {
                state.isCreatingRequest = true;
            })
            .addCase(createsRelocationRequest.fulfilled, (state) => {
                state.successSubmissionModalVisible = true;
                state.isCreatingRequest = false;
            })
            .addCase(createsRelocationRequest.rejected, (state, {payload}: any) => {
                state.isCreatingRequest = false;
                state.createFormFailed = true;
                state.createFormErrorMessage = payload;
            })
            .addCase(validatesReservation.pending, (state) => {
                state.isValidatingReservation = true;
            })
            .addCase(validatesReservation.fulfilled, (state) => {
                state.isValidatingReservation = false;
                state.validateReservationFailed = false;
            })
            .addCase(validatesReservation.rejected, (state, {payload}: any) => {
                state.isValidatingReservation = false;
                state.validateReservationFailed = true;
                state.validateReservationErrorMessage = payload;
            })
            .addCase(validatesUnitCode.pending, (state) => {
                state.isValidatingUnit = true;
            })
            .addCase(validatesUnitCode.fulfilled, (state) => {
                state.isValidatingUnit = false;
                state.validateUnitFailed = false;
            })
            .addCase(validatesUnitCode.rejected, (state, {payload}: any) => {
                state.isValidatingUnit = false;
                state.validateUnitFailed = true;
                state.validateUnitErrorMessage = payload;
            });
    },
});

export const {
    updateStep,
    updateFormData,
    resetSteps,
    hideConfirmationModal,
    refreshStepPath,
    resetBilledInfo,
    closeCreateFormFailedAlert,
    closeValidateReservationFailedAlert,
    closeValidateUnitCodeFailedAlert,
} = formSubmissionSlice.actions;

export default formSubmissionSlice.reducer;
