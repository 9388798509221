import React, {FC, useState} from "react";
import styles from "./CurrencyComponentV2.module.scss";
import _ from "lodash";

interface CurrencyInput {
  initialValue: number | null;
  onChange: (e: number | null) => any;
  readOnly?: boolean;
}

const cleanAmount = (e: string) => e.replace(/\$|,/g, "");

export const CurrencyComponentV2: FC<CurrencyInput> = (props) => {
  const amountPattern = "^[+-]?([0-9]+?([,][0-9]{0,3})+([.][0-9]{0,2})?|[0-9]+?([.][0-9]{0,2})?|[.][0-9]{0,2})$";
  const urlRegEx = new RegExp(amountPattern);
  const urlRegExToExtract = new RegExp(`(${amountPattern}).*`);

  const {initialValue, onChange, readOnly} = props;
  const [amountDisplayed, setAmountDisplayed] = useState(_.isNil(initialValue) ? "" : `$${initialValue.toString().replace(urlRegExToExtract, "$1")}`);

  const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputAmount = cleanAmount(e.currentTarget.value);

    if (urlRegEx.test(newInputAmount)) {
      setAmountDisplayed("$" + newInputAmount);
      onChange(parseFloat(cleanAmount(newInputAmount.endsWith(".") ? newInputAmount.slice(0, -1) : newInputAmount)));
    } else if (newInputAmount === "") {
      setAmountDisplayed("");
      onChange(null);
    }
  };

  return <input type="text" className={styles.currencyBox} value={amountDisplayed} onChange={handleAmount} readOnly={readOnly} />;
};
